import React from 'react'
import { Helmet } from 'react-helmet'
import SiteContainer from "../../components/site-container";
import ContactForm from "../../components/ContactForm";

class Contact extends React.Component {
    render() {
        return (
            <SiteContainer location={this.props.location} >
                <Helmet>
                    <title>Contact Firstparty</title>
                    <meta name="description" content="Get started with Firstparty, inquire about custom pricing, or just contact the team to learn more." />
                    
                </Helmet>
                <ContactForm />
            </SiteContainer>
        )
    }
}

export default Contact
