import React from 'react'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Card} from "react-bootstrap";

export default class ContactForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            complete: false,
            first_name: "",
            last_name: "",
            email: "",
            message: ""
        };

        this.handleFormChange = this.handleFormChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleFormChange(e) {
        let attr = e.target.getAttribute('name');
        this.setState({
            [attr]: e.target.value
        });
    }

    handleSubmit(e) {
        if(window.firstparty) {
            window.firstparty.track('Form Completed', {
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                email: this.state.email,
                message: this.state.message,
            })
        }

        this.setState({
            complete: true
        })

        e.preventDefault();
    }

    render() {
        const state = this.state

        return (
            <section className=" py-8 py-md-11">
                <div className="container">
                    <div className="row align-items-center ">
                        <div className="col-12 col-lg-8">
                            <h1 className="mb-0 fw-bold">
                                Contact the Firstparty Team
                            </h1>

                            <p className="mb-6 text-muted">
                                We always want to hear from you! Fill out the form below and someone from our team will be in
                                touch as soon as possible.
                            </p>
                            <Card border="primary" className="card-border shadow-light-lg">
                                <Card.Body>
                                {!state.complete ?
                                <form onSubmit={this.handleSubmit}>
                                    <Row className="mb-5">
                                        <Col md={6}>
                                            <div className="form-group mb-3">
                                                <label htmlFor="contactName">First Name</label>
                                                <input required type="text" onChange={this.handleFormChange} value={state.first_name} name="first_name" className="form-control" id="firstname" />
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="form-group mb-3">
                                                <label htmlFor="contactName">Last Name</label>
                                                <input required type="text" onChange={this.handleFormChange} value={state.last_name} name="last_name" className="form-control" id="lastname" />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mb-5">
                                        <Col>
                                            <div className="form-group mb-3">
                                                <label htmlFor="contactEmail">Work Email</label>
                                                <input required type="email" onChange={this.handleFormChange} value={state.email} name="email" className="form-control" id="contactEmail"
                                                    placeholder="myname@company.com" />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mb-5">
                                        <Col>
                                            <div className="form-group mb-3">
                                                <label htmlFor="contactEmail">Message</label>
                                                <textarea onChange={this.handleFormChange} value={state.message} name="message" className="form-control" id="contactMessage"
                                                    placeholder="Question or message for the Firstparty team" />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="mt-3">
                                            <button type="submit" className="btn btn-primary lift w-100">
                                                Send Request
                                            </button>
                                        </Col>
                                    </Row>
                                </form>
                                : <div className="row">
                                        <div className="col-12 justify-content-center">
                                            <p className="font-size-lg text-center">
                                                Thank you! We'll get back to you as soon as possible.
                                            </p>
                                        </div>
                                    </div>}
                                </Card.Body>
                            </Card>

                        </div>
                        <div className="col-lg-4 mt-5 mt-lg-0">
                            <h3 className="mb-0 fw-bold">Email Us</h3>
                            <p>Skip the form, party with us in your inbox. Email us at <a href="mailto:support@firstpartyhq.com">support@firstpartyhq.com</a></p>
                        </div>
                    </div>
                </div>
            </section>

        )
    }
}